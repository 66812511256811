import type { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import mySubscriptionsConfig from './components/MySubscriptions/.component.json';

const editorReady: EditorReadyFn = async () => {
  return Promise.resolve();
};

const getAppManifest: GetAppManifestFn = async ({ appManifestBuilder }) => {
  return appManifestBuilder
    .configureWidget(mySubscriptionsConfig.id, (builder) => {
      builder.behavior().set({ removable: false });
      builder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
    })
    .build();
};

export { editorReady, getAppManifest };
